@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');



#container-user-videos {
    color:rgb(63, 125, 128);
    padding: 10px;
}

.container-header-videos {
    padding: 5px;
    margin-bottom: 20px;
}

.row-header-videos {
    background-color:rgb(46, 44, 44);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-home-videos {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
}
#btn-home-videos:hover {
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}

.col-logo-videos {
    text-align: center;
}

.musicolabo-logo-videos {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-user-logged-videos {
    display: block;
    text-align: center;
}

.userlogged {
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.col-button-videos {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}

#btn-videos-logout {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    opacity: 0.7;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    height: max-content;
    margin-bottom: 10px;
}
#btn-videos-logout:hover {
    color: rgb(39, 77, 79);
    opacity: 1;
    border: 1px solid rgb(39, 77, 79);
}

.picture-header-videos {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.container--mensajes-no-leidos {
    margin-top: 10px;
}

.unread-messages-header-videos {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.unread-messages-header-videos:hover {
    color: rgb(139, 110, 73);
}


.container-videos {
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
    padding: 15px;
    margin-top: 10px;
}

.p {
    margin-top: 25px;
    margin-bottom: 250px;
}

.container-videos hr {
    color: rgb(212, 167, 108);
}

.container-vid {
    padding: 15px;
    margin-top: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.video {
    width: 250px;
    height: 250px;
    margin-right: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
}


@media (max-width: 767.98px) {


    .videos-de {
        text-align: center;
    }

    .container-vid {
        text-align: center;
        justify-content: center;
        padding: 20px;
        margin: auto;
    }

    .video {
        width: 285px;
        height: 325px;
        margin-bottom: 15px;
        margin-right: 0px;
    }

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    .unread-text {
        font-size: smaller;
    }

    .container-user-logged-videos {
        margin-left: 15px;
    }

    .col-logo-videos {
        margin-right: 10px;
    }

    .col-button-videos {
        margin-right: 0px;
    }
    
}

@media (max-width: 375.98px) {

    .container-user-logged-videos {
        margin-left: 0px;
        padding: 0px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .col-logo-videos {
        text-align: center;
        margin-right: 0px;
        width: max-content;
    }

    .row-header-videos {
        padding-left: 5px;
    }
    
}


@media (max-width: 339.98px) {

    #btn-home-videos {
       margin-bottom: 20px;
    }

    .col-logo-videos {
        text-align: center;
        margin: auto;
    }

    .container-user-logged-videos {
        margin: auto;
    }

    .col-button-videos {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .row-header-videos {
        display: block;
        text-align: center;
        margin: auto;
    }
    
}