@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');


.container-header-recover-password {
    padding: 5px;
}

.row-header-recover-password {
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-home-recover-password {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    border-radius: 3px;
    margin-left: 10px;
    margin-top: 20px;
}
#btn-home-recover-password:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}

.col-logo-recover-password {
    text-align: center;
}

.musicolabo-logo-recover-password {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-form-recover-password {
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 8px;
    width: 325px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 221px;
    font-family: "Anta"; /* de google fonts*/
}

.container-form-recover-password h2 {
    color: rgb(63, 125, 128);
    opacity: 0.7;
}

.input-email-form-recover-password {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    margin-bottom: 10px;
    padding-left: 5px;
}
.input-email-form-recover-password::placeholder {
    color: rgb(63, 125, 128);
    opacity: 0.5;
}

#btn-form-recover-password {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    border-radius: 3px;
    margin-top: 3px;
}
#btn-form-recover-password:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}

    .message {
        color: rgb(16, 202, 16);
        margin-top: 10px;
    }

    .error-message-email-recover-password {
        color: red;
        margin-top: 10px;
    }
