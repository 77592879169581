@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nanum+Gothic&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Kanit:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Nanum+Gothic&display=swap');



.container-welcomeScreen {
    padding: 10px;
}

.container-welcome {
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    padding: 15px;
    color: rgb(63, 125, 128);
}

.container-welcome h2 {
opacity: 0.7;}

.container-welcome hr {
    color: rgb(212, 167, 108);
}

#btn-welcome-screen-go-to-user-list {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    width: max-content;
    height: max-content;
}
#btn-welcome-screen-go-to-user-list:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}



