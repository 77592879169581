@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');


.container-header {
    margin-bottom: 20px;
}

.row-header {
    background-color:rgb(46, 44, 44);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
    display: flex;
    width: 100%;
    padding: 15px;
}

.col-redes a {
    color: rgb(212, 167, 108);
    opacity: 0.7;
}
.col-redes a:hover {
    color: rgb(139, 110, 73);
}

.col-logo {
    text-align: center;
}

.musicolabo-logo {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-user-logged-header {
    display: block;
    text-align: center;
}

.userlogged {
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

/*.username-header {
    color: rgb(63, 125, 128);
    margin-top: 3px;
}*/

.picture-header {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.container-mensajes-no-leidos {
    margin-top: 10px;
}

.unread-messages-header {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.unread-messages-header:hover {
    color: rgb(139, 110, 73);
}

.col-buttons-header {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}

#btn-header-login {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    width: 105px;
    height: max-content;
}
#btn-header-login:hover {
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}

#btn-header-register {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    margin-left: 7px;
    width: 105px;
    height: max-content;
}
#btn-header-register:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);

}

#btn-header-logout {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    height: max-content;
    margin-bottom: 10px;
}
#btn-header-logout:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);

}

@media (max-width: 1199.98px) {
    .col-buttons-header {
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 10px;
        margin-right: 5px;

    }
 }

@media (max-width: 991.98px) {
    .col-buttons-header {
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        margin-right: 5px;

    }
 }




@media (max-width: 767.98px) { 

    .col-buttons-header {
        
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-end;
        gap: 7px;
    }

   
    .container-user-logged-header p {
        margin-bottom: 70px;
        color: rgb(46, 44, 44);
        text-decoration: none; /* Esto quita el subrayado del texto */
        color: inherit; /* Esto mantiene el color predeterminado del texto */
    }

    #btn-header-register {
        margin-left: 0px;
    }

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    .unread-text {
        font-size: smaller;
        
    }

    .container-user-logged-header {
        margin-left: 15px;
    }

    .col-logo {
        margin-right: 10px;
    }

    .col-buttons-header {
        margin-right: 0px;
    }
    
}
@media (max-width: 339.98px) {

    #btn-home-header {
       margin-bottom: 20px;
    }

    .col-redes {
        margin-bottom: 20px;
    }

    .col-logo-header {
        text-align: center;
        margin: auto;
    }

    .container-user-logged-header {
        margin: auto;
    }

    .col-buttons-header {
        text-align: center;
        margin: auto;
        margin-top: 20px;
        justify-content: center;
        align-items: center;
    }

    .col-button-header {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .row-header {
        display: block;
        text-align: center;
        margin: auto;
    }
    
}

