@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');


#container-edit-profile {
    padding: 10px;
}

.container-header-edit-profile {
    padding: 5px;
    margin-bottom: 20px;
   
}

.row-header-edit-profile {
    background-color:rgb(46, 44, 44);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-home-edit-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
}
#btn-home-edit-profile:hover {
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}

.col-logo-edit-profile {
    text-align: center;
}

.musicolabo-logo-edit-profile {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-user-logged-edit-profile {
    display: block;
    text-align: center;
}

.userlogged {
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.col-button-edit-profile {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}

#btn-edit-profile-logout {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    opacity: 0.7;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    height: max-content;
    margin-bottom: 10px;
}
#btn-edit-profile-logout:hover {
    color: rgb(39, 77, 79);
    opacity: 1;
    border: 1px solid rgb(39, 77, 79);
}

.picture-header-edit-profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.container-mensajes-no-leidos {
    margin-top: 10px;
}

.unread-messages-header-edit-profile {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.unread-messages-header-edit-profile:hover {
    color: rgb(139, 110, 73);
}


.container-edit {
    color: rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
    padding: 15px;
}
.container-edit hr {
    color: rgb(212, 167, 108);
}

.container-title-and-btn-edit {
    display: flex;
    justify-content: space-between;
}

#btn-edit {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    border-radius: 3px;
}
#btn-edit:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}

.picture-edit-profile1 {
    width: 100px;
    height: 100px;
    margin-bottom: 15px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
}

#btn-delete-picture {
    position: absolute;
    opacity: 0.8;
    margin-left: -70px;
    margin-top: 3px;
}

.nom, .nom-us, .em, .ub, .inst {
    display: flex;
}
.nom h5, .nom-us h5, .em h5, .ub h5, .inst h5 {
    margin-right: 5px;
}
.nom p, .nom-us p, .em p, .ub p, .inst p {
    margin-top: 2px;
    color: rgb(212, 167, 108);
    opacity: 0.8;
}
.pro p {
    color: rgb(212, 167, 108);
    opacity: 0.8;
}
.desc p {
    color: rgb(212, 167, 108);
    opacity: 0.8;
}

.preview-video {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

video {
    width: 250px;
    height: 255px;
    margin-top: 15px;
    margin-right: 10px;
    margin-bottom: 10px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
}

#btn-delete-video {
    position: absolute;
    opacity: 0.8;
    margin-left: -82px;
    margin-top: 22px;
}

.picture-edit-profile2 {
    width: 250px;
    height: 250px;
    margin-bottom: 15px;
    border-radius: 5px;
}

.input-edit-profile {
    background-color: rgb(46, 44, 44);
    padding-left: 5px;
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108); 
    opacity: 0.7;  
    border-radius: 3px;
    margin-bottom: 10px;
}

.input-picture-edit-profile {
    background-color: rgb(46, 44, 44);
    padding-left: 5px;
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108); 
    opacity: 0.7;  
    border-radius: 3px;
    margin-bottom: 10px;
}

.picture-edit-profile2 {
    box-shadow: 0 0 7px rgb(63, 125, 128);

}

.input-video-edit-profile {
    background-color: rgb(46, 44, 44);
    padding-left: 5px;
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108); 
    opacity: 0.7;  
    border-radius: 3px;
    margin-bottom: 10px;
}

.checkboxes {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;   
    border-radius: 3px;
}

.checkboxes label {
    margin-right: 15px;
}

.area1-edit-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    opacity: 0.7;
    border-radius: 3px;
    padding: 3px 8px 3px 8px;
}

.area2-edit-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    opacity: 0.7;
    border-radius: 3px;
    padding: 3px 8px 3px 8px;

}

.container-btns-save-delete {
    display: flex;
    justify-content: space-between;
}

#btn-save {
    background-color: rgb(212, 167, 108);
    color: rgb(46, 44, 44);
    opacity: 0.8;
    border: 1px solid rgb(212, 167, 108);
    border-radius: 3px;
}
#btn-save:hover {
    background-color: rgb(139, 110, 73);
    border: 1px solid rgb(139, 110, 73);
}

.spinner {
    margin-top: 5px;
    border: 4px solid rgb(16, 202, 16);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: rgb(4, 73, 4);
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.save-success-message {
    margin-top: 10px;
    color: rgb(16, 202, 16);
    opacity: 0.7;
}

#btn-delete {
    opacity: 0.7;
}




@media (max-width: 767.98px) { 


    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    .container-user-logged-edit-profile {
        margin-left: 20px;
    }

    .unread-text {
        font-size: smaller;
    }

    .container-user-logged-videos {
        margin-left: 15px;
    }

    .col-logo-edit-profile {
        margin-right: 10px;
    }

    .col-button-edit-profile {
        margin-right: 0px;
    }

    .input-picture-edit-profile {
        width: 300px;

    }
    
    .input-video-edit-profile {
        width: 300px;
    }
    
}

@media (max-width: 375.98px) {

    .container-user-logged-edit-profile {
        margin-left: 0px;
        padding: 0px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .col-logo-edit-profile {
        text-align: center;
        margin-right: 0px;
        width: max-content;
    }

    .row-header-edit-profile {
        padding-left: 5px;
    }
    
}


@media (max-width: 339.98px) {

    #btn-home-edit-profile {
       margin-bottom: 20px;
    }

    .col-logo-edit-profile {
        text-align: center;
        margin: auto;
    }

    .container-user-logged-edit-profile {
        margin: auto;
    }

    .col-button-edit-profile {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .row-header-edit-profile {
        display: block;
        text-align: center;
        margin: auto;
    }
    
}




