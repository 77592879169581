@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');



#container-messages {
    color: rgb(63, 125, 128);
    padding: 10px;
}

.container-header-messages {
    padding: 5px;
    margin-bottom: 20px;
}

.row-header-messages {
    background-color:rgb(46, 44, 44);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-volver-messages {
    background-color:rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    opacity: 0.7;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
}
#btn-volver-messages:hover {
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}

.col-logo-messages {
    text-align: center;
}

.musicolabo-logo-messages {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-user-logged-messages {
    display: block;
    text-align: center;
}

.userlogged {
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.col-button-messages {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}

#btn-messages-logout {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    opacity: 0.7;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    height: max-content;
    margin-bottom: 10px;
}
#btn-messages-logout:hover {
    color: rgb(39, 77, 79);
    opacity: 1;
    border: 1px solid rgb(39, 77, 79);
}

.picture-header-messages {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.container--mensajes-no-leidos {
    margin-top: 10px;
}

.unread-messages-header-messages {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.unread-messages-header-messages:hover {
    color: rgb(139, 110, 73);
}

.container-recibidos {
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    padding: 20px;
}

.spinner-messages {
    margin-top: 5px;
    border: 4px solid rgb(212, 167, 108);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: rgb(63, 125, 128);
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.container-recibidos hr {
    color: rgb(212, 167, 108);
    margin-top: 25px;
}

.mensajes-no-leidos {
    list-style-type: none;
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    padding: 20px;
    margin-top: 15px;
}

.mensajes-no-leidos button {
    background-color:rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.8;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 10px;
}
.mensajes-no-leidos button:hover {
    color: rgb(139, 110, 73);
    border: 1px solid rgb(139, 110, 73);
}

.mensajes-leidos {
    list-style-type: none;
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    padding: 20px;
    margin-top: 15px;
}

.link-messages {
    text-decoration: none;
    color: rgb(212, 167, 108);
    opacity: 0.8;
}
.link-messages:hover {
    color: rgb(139, 110, 73);
}

.container-reply {
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 3px;
    padding: 10px;
    margin-top: 20px;
    margin-bottom: 10px;
}

.mensajes-leidos button {
    background-color:rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.8;
    border-radius: 3px;
    margin-right: 5px;
    margin-top: 10px;
}
.mensajes-leidos button:hover {
    color: rgb(139, 110, 73);
    border: 1px solid rgb(139, 110, 73);
}

#btn-delete-message {
    background-color:rgb(46, 44, 44);
    color: rgb(241, 100, 100);
    border: 1px solid rgb(241, 100, 100);
    box-shadow: 0 0 7px rgb(241, 100, 100);
    opacity: 0.8;
    border-radius: 3px;
    margin-top: 15px;
}
#btn-delete-message:hover {
    color: rgb(158, 68, 68);
    border: 1px solid rgb(158, 68, 68);
}

.reply-message-popup {
    position: fixed; /* El elemento permanece en la misma posición independientemente del desplazamiento */
    bottom: 40%; /* Posiciona el mensaje emergente al 40% de la parte inferior */
    left: 50%; /* Posiciona el mensaje emergente en el centro horizontal */
    transform: translateX(-50%); /* Centra el mensaje emergente horizontalmente */
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    padding: 20px;
    z-index: 1000; /* Asegúrate de que el mensaje emergente esté por encima de otros elementos */
    box-shadow: 0 0 5px rgb(212, 167, 108); /* Añade sombra */
  }
  
    
  .reply-message-popup #reply-popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    opacity: 0.9;
    border-radius: 3px;
    color:  rgb(46, 44, 44);
  }
  .reply-message-popup #reply-popup-close:hover {
    border: 1px solid rgb(46, 44, 44);
    border-radius: 5px;
  }
 
  .reply-message-popup h2 {
    margin-top: 25px;
  }

  .reply-message-popup textarea {
    background-color: rgb(46, 44, 44);
    padding: 5px;
    color: rgb(212, 167, 108);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .reply-message-popup #btn-reply-message-popup-submit {
    background-color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    opacity: 0.9;
    border-radius: 3px;
    color:  rgb(46, 44, 44);
  }
  .reply-message-popup #btn-reply-message-popup-submit:hover {
    border: 1px solid rgb(46, 44, 44);
    border-radius: 5px;
  }

  .spinner-send-reply-messages {
    margin-top: 5px;
    border: 4px solid rgb(16, 202, 16);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: rgb(4, 73, 4);
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .reply-message-popup span {
    color: rgb(16, 202, 16);
    margin-top: 10px;
    opacity: 0.7;
  }

  @media (max-width: 767.98px) {

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    .unread-text {
        font-size: smaller;
    }

    .container-user-logged-messages {
        margin-left: 15px;
    }

    .col-logo-messages {
        margin-right: 10px;
    }

    .col-button-messages {
        margin-right: 0px;
    }
    
}


@media (max-width: 375.98px) {

    .container-user-logged-messages {
        margin-left: 0px;
        padding: 0px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .col-logo-messages {
        text-align: center;
        margin-right: 0px;
        width: max-content;
    }

    .row-header-messages {
        padding-left: 5px;
    }
    
}


@media (max-width: 339.98px) {

    #btn-volver-messages {
       margin-bottom: 20px;
    }

    .col-logo-messages {
        text-align: center;
        margin: auto;
    }

    .container-user-logged-messages {
        margin: auto;
    }

    .col-button-messages {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .row-header-messages {
        display: block;
        text-align: center;
        margin: auto;
    }
    
}

