@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');



#container-login {
    padding: 10px;
}

.container-header-login {
    padding: 5px;
}

.row-header-login {
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-home-login {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    border-radius: 3px;
    margin-left: 10px;
    margin-top: 20px;
}
#btn-home-login:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}

.col-logo-login {
    text-align: center;
}

.musicolabo-logo-login {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-form-login {
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 8px;
    max-width: 250px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 12px;
    font-family: "Anta"; /* de google fonts*/
}

.container-form-login h2 {
    color: rgb(63, 125, 128);
    opacity: 0.7;
}

.input-email-form-login {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    margin-bottom: 10px;
    padding-left: 5px;
}
.input-email-form-login::placeholder {
    color: rgb(63, 125, 128);
    opacity: 0.5;
}

.input-password-form-login {
    background-color: rgb(46, 44, 44);;
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    padding-left: 5px;
}
.input-password-form-login::placeholder {
    color: rgb(63, 125, 128);
    opacity: 0.5;
}

.container-link-recover-password {
    color: rgb(63, 125, 128);
    margin-top: 8px;
}

#link-recover-password {
    text-decoration: none;
    color: rgb(212, 167, 108);
    opacity: 0.7;
}
#link-recover-password:hover {
    color: rgb(139, 110, 73);
}

#btn-form-login {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    opacity: 0.7;
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);   
    border-radius: 3px;
    margin-top: 3px;
}
#btn-form-login:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
    border: 1px solid rgb(139, 110, 73);
}

.error-message-email-login {
    color: red;
    margin-top: 5px;
}

.container-link-register {
    color: rgb(63, 125, 128);
    margin-top: 15px;
}

#link-register {
    text-decoration: none;
    color: rgb(212, 167, 108);
    opacity: 0.7;
}
#link-register:hover {
    color: rgb(139, 110, 73);
}



@media (max-width: 767.98px) {

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    
    #btn-home-login {
        background-color: rgb(46, 44, 44);
        color: rgb(212, 167, 108);
        opacity: 0.7;
        border: 1px solid rgb(212, 167, 108);
        box-shadow: 0 0 7px rgb(212, 167, 108);   
        border-radius: 3px;
        margin-left: 0px;
        margin-top: 20px;
    }

}