@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');




.container-users-list {
    padding: 10px;
}

.row-list {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
}

.cards {
    color: rgb(63, 125, 128);
    max-width: 380px;    
    height: 520;
    border-radius: 5px;
    margin: 10px 10px 20px 10px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
}

.card-img-top {
    max-width: 400px;
    height: 300px;
    border-bottom: 1px solid rgb(63, 125, 128);
    border-radius: 5px 5px 0px 0px;
}

.card-body {
    padding: 3px 7px 3px 7px ;
}

.user {
    display: flex;
    justify-content: space-between;
    padding-right: 5px;
    margin-bottom: 10px;
}

.card-user-name {
    margin-top: 1px;
}

.link-user {
    text-decoration: none;
    color: rgb(212, 167, 108);
    opacity: 0.7;
    margin-top: 8px;
}
.link-user:hover {
    color:rgb(139, 110, 73);
}

.card-city {
    margin-top: 0px;
    margin-bottom: 25px;
    border-bottom: 1px outset rgb(212, 167, 108);
}

.card-instruments {
    height: max-content;
    margin-bottom: 2px;
    border-bottom: 1px outset rgb(212, 167, 108);
}

.container-purpose {
    height: 125px;
    margin-top: 10px;
    margin-bottom: 40px;
}

.container-link {
    display: flex;
    justify-content: space-between;
    padding: 5px 0px 8px 10px;
}

#link-videos {
    text-decoration: none;
    color: rgb(212, 167, 108);
    opacity: 0.7;
}
#link-videos:hover {
    color:rgb(139, 110, 73);
}

#button-message {
    border: 0px;
    background-color: transparent;
    color: rgb(212, 167, 108);
    opacity: 0.7;
}
#button-message:hover {
    color:rgb(139, 110, 73);

}

.message-popup {
    position: fixed; /* El elemento permanece en la misma posición independientemente del desplazamiento */
    bottom: 40%; /* Posiciona el mensaje emergente al 40% de la parte inferior */
    left: 50%; /* Posiciona el mensaje emergente en el centro horizontal */
    transform: translateX(-50%); /* Centra el mensaje emergente horizontalmente */
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    padding: 20px;
    z-index: 1000; /* Asegúrate de que el mensaje emergente esté por encima de otros elementos */
    box-shadow: 0 0 7px rgb(212, 167, 108); /* Añade sombra */
  }
  
    
  .message-popup #popup-close {
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
    background-color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    opacity: 0.9;
    border-radius: 3px;
    color:  rgb(46, 44, 44);
    
  }
  .message-popup #popup-close:hover {
    border: 1px solid rgb(46, 44, 44);
    border-radius: 5px;
  }
 
  .message-popup h2 {
    margin-top: 25px;
  }

  .message-popup textarea {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    padding: 5px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  .message-popup #btn-message-popup-submit {
    background-color: rgb(212, 167, 108);
    border: 1px solid rgb(212, 167, 108);
    opacity: 0.9;
    border-radius: 3px;
    color:  rgb(46, 44, 44);
  }
  .message-popup #btn-message-popup-submit:hover {
    border: 1px solid rgb(46, 44, 44);
    border-radius: 5px;
  }

  .spinner-send-messages {
    margin-top: 5px;
    border: 4px solid rgb(16, 202, 16);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: rgb(4, 73, 4);
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .message-popup span {
    color: rgb(16, 202, 16);
    margin-top: 10px;
    opacity: 0.7;
  }


  
@media (max-width: 991.98px) {
    
   .row-list {
    padding-right: 8px;
   }

    .cards {
        margin-right: 20px;
        margin-bottom: 20px;
    }

    .container-purpose {
        margin-bottom: 2px;
    }

    .container-link {
        margin-bottom: 2px;
    }

 }
 

@media (max-width: 767.98px) {
    
    .row-list {
        display: block;
        padding-right: 20px;
    }

    .link-user {
        margin-top: 5px;
    }

    .container-purpose {
        margin-bottom: 2px;
    }

    .cards {
       margin: auto;
       margin-bottom: 30px;
    }

    .container-link {
        margin-bottom: 2px;
    }



 }
