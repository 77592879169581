@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');



.navbar {
    background-color: rgb(46, 44, 44);
    color: rgb(212, 167, 108);
    border-radius: 5px;
    box-shadow: 0 0 7px rgb(212, 167, 108);
    padding: 10px;
   /* position: relative; /* Posición inicial */
}

/*.navbar.fixed-top {
    margin: auto;
    margin-top: 0.4px;
    width: 97.3%;
    position: fixed;
}*/

.navbar-brand {
    color: rgb(63, 125, 128);
    font-family: "Monoton";
    font-size: large;
    margin-top: 8px;
    margin-left: 20px;
    margin-right: 50px;
    align-items: center;
    justify-self: center;
    text-decoration: none;
}
.navbar-brand:hover {
    color: rgb(39, 77, 79);
}

.nav-link {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.nav-link:hover {
    color: rgb(139, 110, 73);
    opacity: 1;
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    width: 300px;
    justify-content: space-between;
}

#navbar-filter {
    display: flex;
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
}

#input-instrument-navbar {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
}
#input-instrument-navbar::placeholder {
    color: rgb(212, 167, 108);
    opacity: 0.4;
}
#input-instrument-navbar:focus, #input-instrument-navbar:active {
    box-shadow: none !important;  /* Elimina el resplandor que suele aparecer */
    background-color:rgb(46, 44, 44) !important; /* Color de fondo cuando está enfocado */
    color: rgb(63, 125, 128) !important; /* Color de texto cuando está enfocado */
    border: 2px solid rgb(212, 167, 108) !important; /* Color y anchura del borde cuando está enfocado */
  }

#input-city-navbar {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
}
#input-city-navbar::placeholder {
    color: rgb(212, 167, 108);
    opacity: 0.4;
}
#input-city-navbar:focus, #input-city-navbar:active {
    box-shadow: none !important; /* Elimina el resplandor que suele aparecer */
    background-color:rgb(46, 44, 44) !important; /* Color de fondo cuando está enfocado */
    color: rgb(63, 125, 128) !important; /* Color de texto cuando está enfocado */
    border: 2px solid rgb(212, 167, 108) !important; /* Color y anchura del borde cuando está enfocado */
  }

#btn-filter {
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
}
#btn-filter:hover {
    background-color: rgb(46, 44, 44);
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}
#btn-filter:focus {
    background-color:rgb(46, 44, 44); /* Color de fondo cuando está enfocado */
    color: rgb(39, 77, 79); /* Color de texto cuando está enfocado */
    border: 1px solid rgb(39, 77, 79); /* Color del borde cuando está enfocado */
  }

.alert-warning {
    background-color: rgb(63, 125, 128);
    border: 2px solid rgb(63, 125, 128);
    color:rgb(46, 44, 44);
    margin-top: 30px;
    margin-right: 10px;
    margin-left: 10px;
    text-align: center;
    padding: 3px;
}

.custom-toggler {
    width: min-content;
    margin-right: 10px;
    border: 2px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    color: transparent;
}
.custom-toggler:hover {
    border: 3px solid rgb(63, 125, 128);
}
.custom-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(63, 125, 128)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}



@media (max-width: 991.98px) {

    .navbar-nav {
        margin-left: 20px;
    }

   /* .navbar.fixed-top {
        margin-top: 0.4px;
        width: 95.5%;
    }*/
}

@media (max-width: 767.98px) {
   
    .navbar-brand {
        margin-left: 0px;
        margin-right: 0px;
    }

    /*.navbar.fixed-top {
        width: 94.5%;
    }*/

    .navbar-nav {
        margin-left: 0px;
        justify-content: flex-start;
    }

    .nav-link {
        margin-right: 18px;
    }

    #navbar-filter {
        margin-left: 0px;
        margin-right: 0px;
    }

    .custom-toggler {
        margin-right: 0px;
    }

    .alert-warning {
        margin-left: 0px;
    }
}

@media (max-width: 352.98px) {

    #navbar-filter {
        display: block;
    }

    /*.navbar.fixed-top {
        width: 94.5%;
    }*/

    #input-instrument-navbar {
        width: 150px;
        margin-bottom: 10px;
    }

    #input-city-navbar {
        width: 150px;
        margin-bottom: 10px;
    }
    
}


