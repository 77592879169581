@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');



#container-user-profile {
    color: rgb(63, 125, 128);
    padding: 10px;
}

.container-header-profile {
    padding: 5px;
    margin-bottom: 20px;
}

.row-header-profile {
    background-color:rgb(46, 44, 44);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    border-radius: 5px;
    display: flex;
    width: 100%;
    padding: 15px;
}

#btn-volver-profile {
    background-color:rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
}
#btn-volver-profile:hover {
    color: rgb(39, 77, 79);
    border: 1px solid rgb(39, 77, 79);
}

.col-logo-profile {
    text-align: center;
}

.musicolabo-logo-profile {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 9px rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-user-logged-profile {
    display: block;
    text-align: center;
}

.userlogged {
    margin-top: 3px;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.col-button-profile {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
}

#btn-profile-logout {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    opacity: 0.7;
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 3px;
    height: max-content;
    margin-bottom: 10px;
}
#btn-profile-logout:hover {
    color: rgb(39, 77, 79);
    opacity: 1;
    border: 1px solid rgb(39, 77, 79);
}

.picture-header-profile {
    width: 40px;
    height: 40px;
    border-radius: 50px;
}

.container--mensajes-no-leidos {
    margin-top: 10px;
}

.unread-messages-header-profile {
    color: rgb(212, 167, 108);
    text-decoration: none;
    opacity: 0.7;
}
.unread-messages-header-profile:hover {
    color: rgb(139, 110, 73);
}

.profile {
    color: rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
    padding: 15px;
}

.profile hr {
    color: rgb(212, 167, 108);
}

.picture-profile {
    margin-bottom: 20px;
    max-width: 250px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
}

.vid-container {
    margin-top: 15px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.vid {
    width: 250px;
    height: 255px;
    margin-right: 5px;
    margin-bottom: 10px;
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 5px;
}



@media (max-width: 767.98px) { 

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }

    .unread-text {
        font-size: smaller;
    }

    .container-user-logged-profile {
        margin-left: 15px;
    }

    .col-logo-profile {
        margin-right: 10px;
    }

    .col-button-profile {
        margin-right: 0px;
    }

    .vid-container {
        gap: 0px;
    }

    .vid {
        margin-bottom: 15px;
    }
    

}

@media (max-width: 375.98px) {

    .container-user-logged-profile {
        margin-left: 0px;
        padding: 0px;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .col-logo-profile {
        text-align: center;
        margin-right: 0px;
        width: max-content;
    }

    .row-header-profile {
        padding-left: 5px;
    }
    
}


@media (max-width: 339.98px) {

    #btn-volver-profile {
       margin-bottom: 20px;
    }

    .col-logo-profile {
        text-align: center;
        margin: auto;
    }

    .container-user-logged-profile {
        margin: auto;
    }

    .col-button-profile {
        text-align: center;
        margin: auto;
        margin-top: 20px;
    }

    .row-header-profile {
        display: block;
        text-align: center;
        margin: auto;
    }
    
}