@import "~bootstrap/dist/css/bootstrap.min.css";
@import url('https://fonts.googleapis.com/css2?family=Monoton&display=swap');




#container-create-profile {
    padding: 10px;
}

.container-header-create-profile {
    padding: 5px;
    margin-bottom: 20px;
}

.row-header-create-profile {
    display: flex;
    width: 100%;
    padding: 15px;
}

.col-logo-create-profile {
    text-align: center;
}

.musicolabo-logo-create-profile {
    margin: auto;
    border-radius: 50px;
    width: 100px;
    height: 100px;
    border: 1px solid rgb(63, 125, 128);
    text-align: center;
    align-items: center;
    justify-content: center;
}

.logo-ini-text {
    font-family: "Monoton";
    margin-top: 27px;
    color: rgb(63, 125, 128);
}

.logo-text {
    font-family: "Monoton";
    color: rgb(63, 125, 128);
}

.container-form-create-profile {
    border: 1px solid rgb(63, 125, 128);
    box-shadow: 0 0 7px rgb(63, 125, 128);
    border-radius: 8px;
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
}

.container-form-create-profile h2 {
    color: rgb(63, 125, 128);
    margin-bottom: 20px;
    text-align: center;
}

.container-form-create-profile hr {
    color: rgb(212, 167, 108);
}

.container-form-create-profile h6  {
    color: rgb(63, 125, 128);
    margin-bottom: 0px;
}

/*.container-form-create-profile input {
    margin-top: 0px;
    margin-bottom: 15px;
}*/

.input-picture-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
}

.input-video-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    width: 100%;
}

.input-name-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-left: 8px;
}

.input-username-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-left: 8px;
}

.input-email-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-left: 8px;
}

.input-ubication-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
    padding-left: 8px;
}

#checks-instruments-form-create-profile {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 5px;
    padding-right: 5px;
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-bottom: 5px;
}

#checks-instruments-form-create-profile label {
    margin-right: 15px;
    color: rgb(63, 125, 128);
}

.input-purpose-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    padding-left: 8px;
}

.input-description-form-create-profile {
    background-color: rgb(46, 44, 44);
    color: rgb(63, 125, 128);
    border: 1px solid rgb(212, 167, 108);
    box-shadow: 0 0 7px rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    padding-left: 8px;
}

#btn-form-create-profile {
    background-color:rgb(212, 167, 108);
    color: rgb(46, 44, 44);
    border: 1px solid rgb(212, 167, 108);
    opacity: 0.7;
    border-radius: 3px;
    margin-top: 0px;
}
#btn-form-create-profile:hover {
    background-color: rgb(139, 110, 73);
    color: rgb(46, 44, 44);
    border: 1px solid rgb(139, 110, 73);
    border-radius: 3px;
}

fieldset {
    border: none;
    padding: 2rem 0;
    border-bottom: 1px solid rgb(63, 125, 128);
  }
  
  fieldset:last-of-type {
    border-bottom: none;
  }

  label {
    display: block;
    margin: 0.5rem 0;
  }

  textarea {
    width: 100%;
    padding-left: 5px;
  }

  .spinner {
    margin-top: 5px;
    border: 4px solid rgb(16, 202, 16);
    width: 25px;
    height: 25px;
    border-radius: 50%;
    border-left-color: rgb(4, 73, 4);
    animation: spin 1s infinite linear;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .container-form-create-profile span {
    color: rgb(16, 202, 16);
    margin-top: 10px;
    opacity: 0.7;
  }


  @media (max-width: 767.98px) {

    .logo-ini-text {
        font-family: "Monoton";
        margin-top: 33px;
        color: rgb(63, 125, 128);
    }

    .logo-text {
        font-size: 22px;
        margin-top: 5px;
        font-family: "Monoton";
        color: rgb(63, 125, 128);
    }
   
}




